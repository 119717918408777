import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Navbar from './components/Navbar';
import { Home } from './components/Home';
import Signup from './components/Signup';
import Login from './components/Login';
import Admin from './components/Dashboard';
import FormComponent from './components/FormComponent';

function App() {
  return (
    <>
     
        <Router>
          <Navbar />
          <div className='container'>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/dashboard"><Admin/></Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/form">
                <FormComponent/>
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>
            </Switch>
          </div>
        </Router>
   
    </>
  );
}

export default App;
