import React, { useState, useEffect } from 'react';
import "./form.css";

const FormComponent = () => {
  const [user, setuser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userdata = await fetch('https://resume-tool-backend.onrender.com/api/auth/getuser', {
          headers: {
            'content-type': 'application/json',
            'auth-token': localStorage.getItem('token'),
          },
          method: 'POST',
        });
        const userdatajson = await userdata.json();
        setuser(userdatajson);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [user]);
  // console.log(user.jsondata)
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [college, setCollege] = useState('');
  const [emaila, setemaila] = useState('');
  const [course, setCourse] = useState('');
  const [github, setGithub] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [numProjects, setNumProjects] = useState(0);
  const [projects, setProjects] = useState([]);
  const [numExperience, setNumExperience] = useState(0);
  const [experience, setExperience] = useState([]);
  const [numPor, setNumPor] = useState(0);
  const [por, setPor] = useState([]);
  const [numEducation, setNumEducation] = useState(0);
  const [education, setEducation] = useState([]);
  const [numAwards, setNumAwards] = useState(0);
  const [awards, setAwards] = useState([]);
  const [numPatents, setNumPatents] = useState(0);
  const [patents, setPatents] = useState([]);
  const [numConferences, setNumConferences] = useState(0);
  const [conferences, setConferences] = useState([]);
  const [numPublications, setNumPublications] = useState(0);
  const [publications, setPublications] = useState([]);
  const [numCourses, setNumCourses] = useState(0);
  const [courses, setCourses] = useState([]);
  const [numCertifications, setNumCertifications] = useState(0);
  const [certifications, setCertifications] = useState([]);

  const [flag, setflag] = useState(true);
  useEffect(() => {
    if (user.jsondata && flag) {
      const {
        name,
        phone,
        college,
        emaila,
        course,
        github,
        linkedin,
        linkedinUrl,
        projects,
        experience,
        por,
        awards,
        patents,
        education,
        conferences,
        publications,
        certifications
      } = user.jsondata;

      setName(name || '');
      setPhone(phone || '');
      setCollege(college || '');
      setemaila(emaila || '');
      setCourse(course || '');
      setGithub(github || '');
      setLinkedin(linkedin || '');
      setLinkedinUrl(linkedinUrl || '');
      setProjects(projects || []);
      setExperience(experience || []);
      setPor(por || []);
      setEducation(education || []);
      setAwards(awards || []);
      setNumAwards(awards?.length || 0);
      setNumProjects(projects.length || 0);
      setNumExperience(experience.length || 0);
      setNumPor(por.length || 0);
      setNumEducation(education.length || 0);
      setConferences(conferences || []);
      setNumConferences(conferences?.length || 0);
      setPublications(publications || []);
      setNumPublications(publications?.length || 0);
      setCourses(courses || []);
      setNumCourses(courses?.length || 0);
      setPatents(patents || []);
      setNumPatents(patents?.length || 0);
      setCertifications(certifications || []);
      setNumCertifications(certifications?.length || 0);
      setflag(false);
    }
  }, [user.jsondata]);

  const handleSubmit = async (e) => {
    let confirm = window.confirm("Please make sure that you have filled all the details. If not, you can save the current details and submit later");
    if(!confirm){
      return;
    }
    e.preventDefault();

    const formData = {
      name,
      phone,
      college,
      emaila,
      course,
      github,
      linkedin,
      linkedinUrl,
      projects,
      experience,
      por,
      awards,
      education,
      patents,
      conferences,
      publications,
      courses,
      certifications
    };

    // Convert JSON object to string
    const jsonData = JSON.stringify(formData);
    console.log(jsonData)
    try {
      const res = await fetch('https://resume-tool-backend.onrender.com/api/auth/update_resume', {
        headers: {
          'content-type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
        method: 'POST',
        body: jsonData
      });
      const resjson = await res.json();
      alert(resjson.message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Create JSON object from form data
    const formData = {
      name,
      phone,
      college,
      emaila,
      course,
      github,
      linkedin,
      linkedinUrl,
      projects,
      experience,
      por,
      awards,
      education,
      patents,
      conferences,
      publications,
      courses,
      certifications
    };

    // Convert JSON object to string
    const jsonData = JSON.stringify(formData);
    console.log(jsonData)
    try {
      const res = await fetch('https://resume-tool-backend.onrender.com/api/auth/save_resume', {
        headers: {
          'content-type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
        method: 'POST',
        body: jsonData
      });
      const resjson = await res.json();
      alert(resjson.message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProjectChange = (index, field, value) => {
    const updatedProjects = [...projects];
    if (!updatedProjects[index]) {
      updatedProjects[index] = {};
    }
    updatedProjects[index][field] = value;
    setProjects(updatedProjects);
  };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperience = [...experience];
    if (!updatedExperience[index]) {
      updatedExperience[index] = {};
    }

    updatedExperience[index][field] = value;

    setExperience(updatedExperience);
  };

  const handleCertificationChange = (index, field, value) => {
    const updatedCertifications = [...certifications];
    if (!updatedCertifications[index]) {
      updatedCertifications[index] = {};
    }
    updatedCertifications[index][field] = value;
    setCertifications(updatedCertifications);
  };

  const handlePublicationChange = (index, field, value) => {
    const updatedPublications = [...publications];
    updatedPublications[index] = {
      ...updatedPublications[index],
      [field]: value,
    };
    setPublications(updatedPublications);
  };

  const handlePatentChange = (index, field, value) => {
    const updatedPatents = [...patents];
    if (!updatedPatents[index]) {
      updatedPatents[index] = {};
    }
    updatedPatents[index][field] = value;
    setPatents(updatedPatents);
  };

  const handleConferenceChange = (index, field, value) => {
    const updatedConferences = [...conferences];
    updatedConferences[index] = {
      ...updatedConferences[index],
      [field]: value,
    };
    setConferences(updatedConferences);
  };

  const handleCourseChange = (index, field, value) => {
    const updatedCourses = [...courses];
    if (!updatedCourses[index]) {
      updatedCourses[index] = {};
    }
    updatedCourses[index][field] = value;
    setCourses(updatedCourses);
  };

  const handleAwardChange = (index, field, value) => {
    const updatedAwards = [...awards];
    if (!updatedAwards[index]) {
      updatedAwards[index] = {};
    }
    updatedAwards[index][field] = value;
    setAwards(updatedAwards);
  };


  const handlePorChange = (index, field, value) => {
    const updatedPor = [...por];
    if (!updatedPor[index]) {
      updatedPor[index] = {};
    }
    updatedPor[index][field] = value;
    setPor(updatedPor);
  };



  const handleEducationChange = (index, field, value) => {
    const updatedEducation = [...education];
    if (!updatedEducation[index]) {
      updatedEducation[index] = {};
    }
    updatedEducation[index][field] = value;
    setEducation(updatedEducation);
  };

  const [visibleSection, setVisibleSection] = useState("personal");
  const handleButtonClick = (sectionName) => {
    setVisibleSection(sectionName);
  };

  return (
    <div>
    <div className="button-container">
        <button
          className={visibleSection === 'personal' ? 'active' : ''}
          onClick={() => handleButtonClick('personal')}
        >
          Personal Details
        </button>
        <button
          className={visibleSection === 'project' ? 'active' : ''}
          onClick={() => handleButtonClick('project')}
        >
          Projects
        </button>
        <button
          className={visibleSection === 'experience' ? 'active' : ''}
          onClick={() => handleButtonClick('experience')}
        >
          Experience
        </button>
        <button
          className={visibleSection === 'awards' ? 'active' : ''}
          onClick={() => handleButtonClick('awards')}
        >
          Awards
        </button>
        <button
          className={visibleSection === 'por' ? 'active' : ''}
          onClick={() => handleButtonClick('por')}
        >
          POR
        </button>
        <button
          className={visibleSection === 'patents' ? 'active' : ''}
          onClick={() => handleButtonClick('patents')}
        >
          Patents
        </button>
        <button
          className={visibleSection === 'publication' ? 'active' : ''}
          onClick={() => handleButtonClick('publication')}
        >
          Publications
        </button>
        <button
          className={visibleSection === 'education' ? 'active' : ''}
          onClick={() => handleButtonClick('education')}
        >
          Education
        </button>
        <button
          className={visibleSection === 'conferences' ? 'active' : ''}
          onClick={() => handleButtonClick('conferences')}
        >
          Conferences
        </button>
        <button
          className={visibleSection === 'courses' ? 'active' : ''}
          onClick={() => handleButtonClick('courses')}
        >
          Courses
        </button>
        <button
          className={visibleSection === 'certifications' ? 'active' : ''}
          onClick={() => handleButtonClick('certifications')}
        >
          Certifications
        </button>
      </div>

    <form onSubmit={handleSubmit}>
      {visibleSection=="personal" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Personal Details</h3>
      <div class="container">
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <br />
        <label>
          Phone:
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </label>
        <br />

        <label>
          Email:
          <input type="email" value={emaila} onChange={(e) => setemaila(e.target.value)} />
        </label>
        <br />

        <label>
          GitHub Username:
          <input type="text" value={github} onChange={(e) => setGithub(e.target.value)} />
        </label>
        <br />
        <label>
          Personal Website URL:
          <input type="text" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
        </label>
        <br />
        <label>
          LinkedIn URL:
          <input type="text" value={linkedinUrl} onChange={(e) => setLinkedinUrl(e.target.value)} />
        </label>
      </div>
      <div className='container'>
        <label>
          College/Company:
          <input type="text" value={college} onChange={(e) => setCollege(e.target.value)} />
        </label>
        <br />
        <label>
          Major/Position:
          <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
        </label>
        <br />
      </div>
      </div>}
      <br />
      {visibleSection==="project" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Project Section</h3>
      <label>

        Number of Projects:
        <input
          type="number"
          value={numProjects}
          onChange={(e) => setNumProjects(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
          
      <br />

      {[...Array(numProjects)].map((_, index) => (<div key={index}>
        <h4>Project {index + 1}</h4>
        <div className="container" >
          <label>
            Title:
            <input
              type="text"
              value={projects[index]?.title || ''}
              onChange={(e) => handleProjectChange(index, 'title', e.target.value)}
            />
          </label>
          <br />
          <label>
            Institute:
            <input
              type="text"
              value={projects[index]?.location || ''}
              onChange={(e) => handleProjectChange(index, 'location', e.target.value)}
            />
          </label>
          <br />
          <label>
            Timeline:
            <input
              type="text"
              value={projects[index]?.timeline || ''}
              onChange={(e) => handleProjectChange(index, 'timeline', e.target.value)}
            />
          </label>
          <br />
          <label>
            GitHub link:
            <input
              type="text"
              value={projects[index]?.githublink || ''}
              onChange={(e) => handleProjectChange(index, 'githublink', e.target.value)}
            />
          </label>
          <br />
          <label>
            GitHub name:
            <input
              type="text"
              value={projects[index]?.githubname || ''}
              onChange={(e) => handleProjectChange(index, 'githubname', e.target.value)}
            />
          </label>
          <br />
          <label>
            Supervisor:
            <input
              type="text"
              value={projects[index]?.supervisor || ''}
              onChange={(e) => handleProjectChange(index, 'supervisor', e.target.value)}
            />
          </label>
          <br />
          <label>
            List of Co-authors:
            <input
              type="text"
              value={projects[index]?.authors || ''}
              onChange={(e) => handleProjectChange(index, 'authors', e.target.value)}
            />
          </label>
          <br />
          <label>
            Course (if course project):
            <input
              type="text"
              value={projects[index]?.courseproj || ''}
              onChange={(e) => handleProjectChange(index, 'courseproj', e.target.value)}
            />
          </label>
          <br />
          <label>
            Description:
            <input
              type="text"
              value={projects[index]?.description || ''}
              onChange={(e) => handleProjectChange(index, 'description', e.target.value)}
            />
          </label>
          <br />
        </div>
      </div>

      ))}
      </div>}

      {visibleSection==="experience" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Experience Section</h3>
      <label>
        Number of Experiences:
        <input
          type="number"
          value={numExperience}
          onChange={(e) => setNumExperience(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numExperience)].map((_, index) => (
        <div key={index}>
          <h4>Experience {index + 1}</h4>
          <div className="container">
            <label>
              Company/University:
              <input
                type="text"
                value={experience[index]?.company || ''}
                onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
              />
            </label>
            <br />
            <label>
              Position:
              <input
                type="text"
                value={experience[index]?.title || ''}
                onChange={(e) => handleExperienceChange(index, 'title', e.target.value)}
              />
            </label>
            <br />
            <label>
              Location:
              <input
                type="text"
                value={experience[index]?.location || ''}
                onChange={(e) => handleExperienceChange(index, 'location', e.target.value)}
              />
            </label>
            <br />
            <label>
              Name of your manager/professor:
              <input
                type="text"
                value={experience[index]?.manager || ''}
                onChange={(e) => handleExperienceChange(index, 'manager', e.target.value)}
              />
            </label>
            <br />
            <label>
              Timeline:
              <input
                type="text"
                value={experience[index]?.timeline || ''}
                onChange={(e) => handleExperienceChange(index, 'timeline', e.target.value)}
              />
            </label>
            <br />
            <label>
              Description:
              <input
                type="text"
                value={experience[index]?.description || ''}
                onChange={(e) => handleExperienceChange(index, 'description', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

        {visibleSection==="awards" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Awards Section</h3>
      <label>
        Number of Awards:
        <input
          type="number"
          value={numAwards}
          onChange={(e) => setNumAwards(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numAwards)].map((_, index) => (
        <div key={index}>
          <h4>Award {index + 1}</h4>
          <div className="container">
            <label>
              Name:
              <input
                type="text"
                value={awards[index]?.name || ''}
                onChange={(e) => handleAwardChange(index, 'name', e.target.value)}
              />
            </label>
            <br />
            <label>
              Year:
              <input
                type="text"
                value={awards[index]?.year || ''}
                onChange={(e) => handleAwardChange(index, 'year', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

      {visibleSection === "por" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>PoRs Section </h3>
      <label>
        Number of Positions of Responsibility:
        <input
          type="number"
          value={numPor}
          onChange={(e) => setNumPor(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numPor)].map((_, index) => (
        <div key={index}>
          <h4>Position of Responsibility {index + 1}</h4>
          <div className="container">
            <label>
              Organization:
              <input
                type="text"
                value={por[index]?.club || ''}
                onChange={(e) => handlePorChange(index, 'club', e.target.value)}
              />
            </label>
            <br />
            <label>
              Institution
              <input
                type="text"
                value={por[index]?.institution || ''}
                onChange={(e) => handlePorChange(index, 'institution', e.target.value)}
              />
            </label>
            <br />
            <label>
              Position:
              <input
                type="text"
                value={por[index]?.position || ''}
                onChange={(e) => handlePorChange(index, 'position', e.target.value)}
              />
            </label>
            <br />
            <label>
              Tenure period:
              <input
                type="text"
                value={por[index]?.tenure || ''}
                onChange={(e) => handlePorChange(index, 'tenure', e.target.value)}
              />
            </label>
            <br />
            <label>
              Your impact:
              <input
                type="text"
                value={por[index]?.description || ''}
                onChange={(e) => handlePorChange(index, 'description', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

        {visibleSection === "education" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Education Section</h3>
      <label>
        Number of Education:
        <input
          type="number"
          value={numEducation}
          onChange={(e) => setNumEducation(parseInt(e.target.value))}
        />
      </label>
      <br />

      {[...Array(numEducation)].map((_, index) => (
        <div key={index}>
          <h4>Education {index + 1}</h4>
          <div className="container">
            <label>
              Start Year:
              <input
                type="text"
                value={education[index]?.startyear || ''}
                onChange={(e) => handleEducationChange(index, 'startyear', e.target.value)}
              />
            </label>
            <label>
              End Year:
              <input
                type="text"
                value={education[index]?.endyear || ''}
                onChange={(e) => handleEducationChange(index, 'endyear', e.target.value)}
              />
            </label>
            <br />
            <label>
              Degree:
              <input
                type="text"
                value={education[index]?.name || ''}
                onChange={(e) => handleEducationChange(index, 'name', e.target.value)}
              />
            </label>
            <label>
              Class Rank:
              <input
                type="text"
                value={education[index]?.rank || ''}
                onChange={(e) => handleEducationChange(index, 'rank', e.target.value)}
              />
            </label>
            <br />
            <label>
              Institute:
              <input
                type="text"
                value={education[index]?.institute || ''}
                onChange={(e) => handleEducationChange(index, 'institute', e.target.value)}
              />
            </label>
            <br />
            <label>
              Grades:
              <input
                type="text"
                value={education[index]?.grades || ''}
                onChange={(e) => handleEducationChange(index, 'grades', e.target.value)}
              />
            </label>
            <label>
              Major:
              <input
                type="text"
                value={education[index]?.major || ''}
                onChange={(e) => handleEducationChange(index, 'major', e.target.value)}
              />
            </label>
            <label>
              Minor:
              <input
                type="text"
                value={education[index]?.minor || ''}
                onChange={(e) => handleEducationChange(index, 'minor', e.target.value)}
              />
            </label>
            <label>
              Thesis Title:
              <input
                type="text"
                value={education[index]?.thesis || ''}
                onChange={(e) => handleEducationChange(index, 'thesis', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

      {visibleSection === "patents" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Patent Section</h3>
      <label>
        Number of Patents:
        <input
          type="number"
          value={numPatents}
          onChange={(e) => setNumPatents(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numPatents)].map((_, index) => (
        <div key={index}>
          <h4>Patent {index + 1}</h4>
          <div className="container">
            <label>
              Names of Inventor:
              <input
                type="text"
                value={patents[index]?.inventor || ''}
                onChange={(e) => handlePatentChange(index, 'inventor', e.target.value)}
              />
            </label>
            <br />
            <label>
              Patent Country:
              <input
                type="text"
                value={patents[index]?.country || ''}
                onChange={(e) => handlePatentChange(index, 'country', e.target.value)}
              />
            </label>
            <br />
            <label>
              Patent Title:
              <input
                type="text"
                value={patents[index]?.title || ''}
                onChange={(e) => handlePatentChange(index, 'title', e.target.value)}
              />
            </label>
            <br />
            <label>
              Patent Year:
              <input
                type="text"
                value={patents[index]?.year || ''}
                onChange={(e) => handlePatentChange(index, 'year', e.target.value)}
              />
            </label>
            <br />
            <label>
              Current Stage:
              <input
                type="text"
                value={patents[index]?.stage || ''}
                onChange={(e) => handlePatentChange(index, 'stage', e.target.value)}
              />
            </label>
            <br />
            <label>
              Invention Number:
              <input
                type="text"
                value={patents[index]?.inventionNumber || ''}
                onChange={(e) => handlePatentChange(index, 'inventionNumber', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

      {visibleSection === "conferences" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Conference Section</h3>
      <label>
        Number of Conferences:
        <input
          type="number"
          value={numConferences}
          onChange={(e) => setNumConferences(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numConferences)].map((_, index) => (
        <div key={index}>
          <h4>Conference {index + 1}</h4>
          <div className="container">
            <label>
              Full names of all authors (including you):
              <textarea
                value={conferences[index]?.authors || ''}
                onChange={(e) => handleConferenceChange(index, 'authors', e.target.value)}
                rows={4}
              />
            </label>
            <br />
            <label>
              Your full name in the paper:
              <input
                type="text"
                value={conferences[index]?.yourFullName || ''}
                onChange={(e) => handleConferenceChange(index, 'yourFullName', e.target.value)}
              />
            </label>
            <br />
            <label>
              Conference Name:
              <input
                type="text"
                value={conferences[index]?.name || ''}
                onChange={(e) => handleConferenceChange(index, 'name', e.target.value)}
              />
            </label>
            <br />
            <label>
              Conference Location:
              <input
                type="text"
                value={conferences[index]?.location || ''}
                onChange={(e) => handleConferenceChange(index, 'location', e.target.value)}
              />
            </label>
            <br />
            <label>
              Conference Year:
              <input
                type="text"
                value={conferences[index]?.year || ''}
                onChange={(e) => handleConferenceChange(index, 'year', e.target.value)}
              />
            </label>
            <br />
            <label>
              Conference Month:
              <input
                type="text"
                value={conferences[index]?.month || ''}
                onChange={(e) => handleConferenceChange(index, 'month', e.target.value)}
              />
            </label>
            <br />
            <label>
              Current Stage:
              <select
                value={conferences[index]?.stage || ''}
                onChange={(e) => handleConferenceChange(index, 'stage', e.target.value)}
              >
                <option value="">-- Select Stage --</option>
                <option value="Submitted">Submitted</option>
                <option value="Accepted">Accepted</option>
                <option value="Presented">Presented</option>
              </select>
            </label>
            <br />
            <label>
              Title of your project/presentation:
              <input
                type="text"
                value={conferences[index]?.projectTitle || ''}
                onChange={(e) => handleConferenceChange(index, 'projectTitle', e.target.value)}
              />
            </label>
            <br />
            <label>
              URL to the presentation/abstract:
              <input
                type="text"
                value={conferences[index]?.presentationUrl || ''}
                onChange={(e) => handleConferenceChange(index, 'presentationUrl', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

      {visibleSection === "courses" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Course Section</h3>
      <label>
        Number of Courses:
        <input
          type="number"
          value={numCourses}
          onChange={(e) => setNumCourses(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numCourses)].map((_, index) => (
        <div key={index}>
          <h4>Course {index + 1}</h4>
          <div className="container">
            <label>
              Name:
              <input
                type="text"
                value={courses[index]?.name || ''}
                onChange={(e) => handleCourseChange(index, 'name', e.target.value)}
              />
            </label>
            <br />
            <label>
              Year:
              <input
                type="text"
                value={courses[index]?.year || ''}
                onChange={(e) => handleCourseChange(index, 'year', e.target.value)}
              />
            </label>
            <br />
            <label>
              Institute:
              <input
                type="text"
                value={courses[index]?.institute || ''}
                onChange={(e) => handleCourseChange(index, 'institute', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

        {visibleSection === "publication" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Publication Section</h3>
      <label>
        Number of Publications:
        <input
          type="number"
          value={numPublications}
          onChange={(e) => setNumPublications(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />
      {[...Array(numPublications)].map((_, index) => (
        <div key={index}>
          <h4>Publication {index + 1}</h4>
          <div className="container">
            <label>
              Full names of all authors (including you):
              <textarea
                value={publications[index]?.authors || ''}
                onChange={(e) => handlePublicationChange(index, 'authors', e.target.value)}
                rows={4}
              />
            </label>
            <br />
            <label>
              Your full name in the paper:
              <input
                type="text"
                value={publications[index]?.yourFullName || ''}
                onChange={(e) => handlePublicationChange(index, 'yourFullName', e.target.value)}
              />
            </label>
            <br />
            <label>
              Journal Name:
              <input
                type="text"
                value={publications[index]?.journalName || ''}
                onChange={(e) => handlePublicationChange(index, 'journalName', e.target.value)}
              />
            </label>
            <br />
            <label>
              Current Stage:
              <select
                value={publications[index]?.stage || ''}
                onChange={(e) => handlePublicationChange(index, 'stage', e.target.value)}
              >
                <option value="">-- Select Stage --</option>
                <option value="Published">Published</option>
                <option value="To be submitted">To be submitted</option>
                <option value="Under review">Under review</option>
              </select>
            </label>
            <br />
            <label>
              Year of Publishing:
              <input
                type="text"
                value={publications[index]?.year || ''}
                onChange={(e) => handlePublicationChange(index, 'year', e.target.value)}
              />
            </label>
            <br />
            <label>
              Title of your project/paper:
              <input
                type="text"
                value={publications[index]?.projectTitle || ''}
                onChange={(e) => handlePublicationChange(index, 'projectTitle', e.target.value)}
              />
            </label>
            <br />
            <label>
              URL to the paper:
              <input
                type="text"
                value={publications[index]?.paperUrl || ''}
                onChange={(e) => handlePublicationChange(index, 'paperUrl', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}

      {visibleSection==="certifications" && <div>
      <h3 style={{ textAlign: "center", color: "blue", marginTop: "15px", marginBottom: "15px" }}>Certifications Section</h3>
      <label>
        Number of Certifications:
        <input
          type="number"
          value={numCertifications}
          onChange={(e) => setNumCertifications(parseInt(e.target.value))}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            margin: '8px 0',
            width: '100%',
          }}
        />
      </label>
      <br />

      {[...Array(numCertifications)].map((_, index) => (
        <div key={index}>
          <h4>Certification {index + 1}</h4>
          <div className="container">
            <label>
              Certification Title:
              <input
                type="text"
                value={certifications[index]?.title || ''}
                onChange={(e) => handleCertificationChange(index, 'title', e.target.value)}
              />
            </label>
            <br />
            <label>
              Year and Month:
              <input
                type="text"
                value={certifications[index]?.date || ''}
                onChange={(e) => handleCertificationChange(index, 'date', e.target.value)}
              />
            </label>
            <br />
            <label>
              Organization/Platform:
              <input
                type="text"
                value={certifications[index]?.organization || ''}
                onChange={(e) => handleCertificationChange(index, 'organization', e.target.value)}
              />
            </label>
            <br />
          </div>
        </div>
      ))}
      </div>}


    <div className="button-container">
      <button
      className='active'
        onClick={handleSave}
      >
        Save Details
      </button>
      <button
      className='active'
        onClick={handleSubmit}
      >
        
        Submit Details
      </button>
    </div>
    </form>
    </div>
  );
};

export default FormComponent;