import React, { useState, useEffect } from "react";

const Admin = () => {
  const [user, setuser] = useState({});
  const [students, setstudents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userdata = await fetch("https://resume-tool-backend.onrender.com/api/auth/getuser", {
          headers: {
            "content-type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          method: "POST",
        });
        const userdatajson = await userdata.json();
        setuser(userdatajson);
      } catch (error) {
        console.error(error);
      }
    };

    async function fetchstudents() {
      try {
        const response = await fetch("https://resume-tool-backend.onrender.com/api/auth/users", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
            // Include the authentication token or any other required headers
            // For example:
            // 'Authorization': 'Bearer <token>'
          },
        });

        if (!response.ok) {
          throw new Error("Error retrieving user data");
        }

        const users = await response.json();
        setstudents(users);
        // Set the students based on the response

        console.log(students);
        // Do something with the students array
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    }

    fetchData();
    if (user.isMentor) {
      fetchstudents();
    }
  }, [user, students]);

  const [copied, setCopied] = useState(false);
  const [showcode, setshowcode] = useState(false);
  const [code, setcode] = useState("");

  const handleGenerateCode = (student) => {
    setshowcode(true);
    setcode(student.resumedata);
    setCopied(false);
  };

  const handleCopyCode = () => {
    const codeBox = document.getElementById("code-box");
    codeBox.select();
    document.execCommand("copy");
    setCopied(true);
  };

  const handleResumeSentChange = async (student) => {
    try {
      const response = await fetch(
        `https://resume-tool-backend.onrender.com/api/auth/changestatus/${student.email}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mentorid: "" }),
        }
      );

      if (response.ok) {
        // Perform any necessary UI updates or notifications here
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle error scenario here
    }
  };

  const styles = {
    table: {
      borderCollapse: "collapse",
      width: "100%",
    },
    th: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "left",
      backgroundColor: "#f2f2f2",
    },
    td: {
      border: "1px solid #ddd",
      padding: "8px",
    },
    button: {
      padding: "6px 10px",
      borderRadius: "4px",
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      cursor: "pointer",
    },
    mycontainer: {
      marginTop: "20px",
    },
    codeBox: {
      width: "100%",
      height: "150px",
      padding: "8px",
      fontSize: "14px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      resize: "vertical",
    },
    copyButton: {
      marginTop: "10px",
      padding: "6px 10px",
      borderRadius: "4px",
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      cursor: "pointer",
    },
    copiedMessage: {
      display: "inline-block",
      marginLeft: "10px",
      color: "green",
    },
    dashboardcontainer: {
      marginLeft: "10px",
      marginRight: "10px",
      paddingLeft: "10px",
      paddingRight: "15px",
    },
  };

  return (
    <div>
      {!localStorage.getItem("token") ? (
        <h2>
          You are not authorized to access the page. Kindly Login/Signup and try
          again.
        </h2>
      ) : (
        <div style={{ marginLeft: "40px", marginRight: "40px" }}>
          {user.isMentor && (
            <div>
              <h2>{user.name}</h2>
              <h3>{user.email}</h3>
              {/* <pre>{!(user.isMentor) && user.resumedata}</pre> */}

              <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                <table
                  style={{
                    ...styles.table,
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={styles.th}>Name</th>
                      <th style={styles.th}>Email</th>
                      <th style={styles.th}>Generate Overleaf Code</th>
                      <th style={styles.th}>Confirmation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student) => (
                      <tr key={student.email}>
                        <td style={styles.td}>{student.name}</td>
                        <td style={styles.td}>{student.email}</td>
                        <td style={styles.td}>
                          <button
                            style={styles.button}
                            onClick={() => handleGenerateCode(student)}
                          >
                            Generate Code
                          </button>
                        </td>
                        <td style={styles.td}>
                          <button
                            style={styles.button}
                            onClick={() => handleResumeSentChange(student)}
                          >
                            Resume sent
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {showcode && (
                <div style={styles.mycontainer}>
                  <textarea
                    id="code-box"
                    value={code}
                    readOnly
                    style={styles.codeBox}
                  />
                  <button onClick={handleCopyCode} style={styles.copyButton}>
                    Copy the Code
                  </button>
                  {copied && (
                    <span style={styles.copiedMessage}>Code copied!</span>
                  )}
                </div>
              )}

              <a
                href="https://www.overleaf.com"
                target="_blank"
                style={{ color: "blue", textDecoration: "none" }}
              >
                Go to Overleaf
              </a>
            </div>
          )}
          {!user.isMentor && (
            <h2>You need to be a mentor to access this page.</h2>
          )}
        </div>
      )}
    </div>
  );
};

export default Admin;
