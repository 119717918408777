import "./home.css"

export const Home = () => {

    return (
        <div className="typewriter-container">
      <h1 className="typewriter-text">
        <span>Create your Resume</span>
      </h1>
    </div>
    )

}
